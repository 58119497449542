import { Avatar, Box, Chip, Typography } from "@mui/material";

type TeamRole = string

interface TeamMember {
  image?: string;
  name: string;
  role: TeamRole;
}

interface TeamSectionProps {
  title: string;
  people: TeamMember[];
}

export default function TeamSection(props: TeamSectionProps) {
  /**
   * For large (>= 4) team sections, we want to inject some padding so it
   * doesn't have 3 people in the first row and 1 in the second row.
   *
   * This, if leveraged when calculating width of the person section,
   * allows us to predictably wrap after 2 people instead of 3 people.
   */
  const paddingPercentSm = props.people.length >= 4 ? 0.2 : 0;
  const paddingPercentXs = props.people.length >= 4 ? 0.1 : 0;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}>
      <Typography variant="h4" align="center" m={3}>
        {props.title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          flexGrow: 1,
          justifyContent: "center",
          gap: {
            xs: 2,
            sm: 3
          },
          maxWidth: "100%",

          pl:
            props.people.length >= 4
              ? {
                  xs: `${paddingPercentXs * 100}%`,
                  sm: `${paddingPercentSm * 100}%`,
                  md: 0
                }
              : 0,
          pr:
            props.people.length >= 4
              ? {
                  xs: `${paddingPercentXs * 100}%`,
                  sm: `${paddingPercentSm * 100}%`,
                  md: 0
                }
              : 0
        }}
      >
        {props.people.map((person) => (
          <Box
            key={person.name}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
              // This is so that sections with fewer people don't have larger avatars
              maxWidth: {
                xs: `min(170px,${`${30 / (1 - 2 * paddingPercentXs)}%`})`,
                sm: `min(170px,${`${30 / (1 - 2 * paddingPercentSm)}%`})`,
                md: "170px"
              }
            }}
          >
            <Avatar
              variant="rounded"
              sx={{
                width: "100%",
                height: "auto",
                aspectRatio: "1/1"
              }}
              src={person.image}
            >
              
            </Avatar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                flexGrow: 1,
                mt: 0.5
              }}
            >
              <Typography variant="body1" align="center">
                {person.name}
              </Typography>
              <Typography variant="body1" align="center">
                <em>{person.role}</em>
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
