import z from "zod";

const Environment = z
  .literal("local-dev")
  .or(z.literal("dev"))
  .or(z.literal("prod"))
  .or(z.literal("test"));

type Environment = z.infer<typeof Environment>;

const envString = import.meta.env.MODE;
let env: Environment;

try {
  env = Environment.parse(envString);
} catch {
  console.error(
    `Unexpected environment specified (${envString}), falling back to "local".`
  );
  env = "local-dev";
}

function plausibleDataDomain() {
  switch (env) {
    case "local-dev":
      return "localhost";
    case "dev":
      return "dev.thegivinggallery.com";
    case "prod":
      return "thegivinggallery.com";
  }
}

function bunnyCdnBaseUrl() {
  switch (env) {
    case "prod":
      return "https://assets.thegivinggallery.com";
    default:
      return "https://assets.dev.thegivinggallery.com";
  }
}

function bunnyCdnProductsUrl() {
  switch (env) {
    case "prod":
      return "https://product.assets.thegivinggallery.com";
    default:
      return "https://product.assets.dev.thegivinggallery.com";
  }
}

const bunnyCdnStaticAssetsUrl = "https://assets.thegivinggallery.com";

// Your web app's Firebase configuration

const firebaseDevConfig = {
  apiKey: "AIzaSyBAfQFUYmAtej2JPYeHW7MJ0RVuGfSfGUs",
  authDomain: "thegivinggallery-dev.firebaseapp.com",
  projectId: "thegivinggallery-dev",
  storageBucket: "thegivinggallery-dev.appspot.com",
  messagingSenderId: "319061634315",
  appId: "1:319061634315:web:939a2edc0cfb1a4278460e"
};

const firebaseProdConfig = {
  apiKey: "AIzaSyCqm-bkI252uV0reTf2bvMVe3EnNv6oyjQ",
  authDomain: "thegivinggallery-prod.firebaseapp.com",
  projectId: "thegivinggallery-prod",
  storageBucket: "thegivinggallery-prod.appspot.com",
  messagingSenderId: "846309674328",
  appId: "1:846309674328:web:8c52935381fc250d61692d"
};

// TODO: change this to also respect the prod env
const againstProdFirebase = import.meta.env.VITE_FORCE_PROD_FIREBASE;

// it is OK to hardcode these in the client, though important we only use the live key for live payments
const stripePublishableKey = {
  live: "pk_live_51LB0a0HDMnnFhNH1SCxED176e5d2gT8MK2JBCWf0qbKyutZj5knIH5nNvmrBqWqRG2obsKwusSvw2ZAHi6mLSEzn00UfEuX1we",
  dev: "pk_test_51LB0a0HDMnnFhNH1Y33JY1omzkEJngc6CHa0tz6uhRI7M6sJ66NEcGP0H0V6pn8tTynYHxjdG7Nvqyrg498CWswS00iKI5wynF"
};

const Config = {
  firebase: {
    emulators: {
      ports: {
        functions: import.meta.env.VITE_FB_EMULATOR_FUNCTIONS,
        firestore: import.meta.env.VITE_FB_EMULATOR_FIRESTORE,
        storage: import.meta.env.VITE_FB_EMULATOR_STORAGE
      }
    },
    config:
      againstProdFirebase || env === "prod"
        ? firebaseProdConfig
        : firebaseDevConfig
  },

  printful: {
    /**
     * We have an overall whitelist on the server side,
     * but certain products haven't been well tested yet
     * or have printfiles set properly.
     *
     * i.e., it's important for us not to trust this client-side config,
     * but overriding it would just lead to a bad experience for the customer,
     * not actually exploit us
     */
    enforceProductWhitelist: env === "prod" ? true : false,
    productsWhitelist: [1, 3, 588, 616] // 1 == matte print, 3 == canvas, 616== thin canvas, 588 == metal print
  },

  plausible: {
    baseUrl: "https://plausible.thegivinggallery.com",
    dataDomain: plausibleDataDomain(),
    usingLocalhost: env === "local-dev" // will eventually need test or other envs here
  },
  bunnyCdn: {
    // FIXME: We aren't using the prod CDN for user uploaded images yet, this will need to change soon.
    baseUrl: bunnyCdnBaseUrl(),
    productUrl: bunnyCdnProductsUrl(),
    /**
     * URL for static assets for landing pages and site decoration. Always points to prod, for now.
     */
    staticAssetsBaseUrl: bunnyCdnStaticAssetsUrl
  },
  defaultCoverImagePath: "images/default-cover-image.jpg",
  ux: {
    navBarWidth: "1200px",
    checkoutDisabled: false,
    moreNonprofitsComingSoon: false
  },
  stripe: {
    publishableKey:
      env === "prod" ? stripePublishableKey.live : stripePublishableKey.dev
  }
};

export default Config;
